<template>
    <div class="course_wrapper">
    <b-card title="Filters">
      <b-row>
        <b-col md="2" sm="4">
             <b-form-group    
                class="mb-0"
                label="Course"
                label-for="filterCourse"
              > 
                <select id="filterCourse" v-model="courseFilter" @change="filterCourceName($event)">
                    <option selected>All</option>
                    <option v-for='(course,ind) in courseNameFilter' :key="ind">{{course.courseName}}</option>
                </select>
              </b-form-group>
        </b-col>
        <b-col md="2" sm="4">
             <b-form-group class="mb-0" label="University" label-for="filterUniversity">     
                <select v-model="universityFilter" id='filterUniversity' @change="filterUniversityName($event)">
                    <option selected>All</option>
                    <option v-for='(uni,ind) in universityFilterArry' :key="ind">{{uni.universityName}}</option>
                </select>
              </b-form-group>
        </b-col>
        <b-col md="2" sm="4">           
            <b-form-group class="mb-0" label="Field" label-for="Field">     
            <select v-model="fieldFilter" id='Field' @change="filterFieldName($event)">
                <option selected>All</option>
                <option v-for='(field,ind) in fieldFilterArry' :key="ind">{{field.fieldName}}</option>
            </select>
          </b-form-group>              
        </b-col>
        <b-col md="2" sm="4">
            <b-form-group class="mb-0" label="Intake" label-for="Intake">     
            <select v-model="intakeFilter" id='Intake' @change="filterIntakeName($event)">
                <option selected>All</option>
                <option v-for='(field,ind) in intakeFilterArry' :key="ind">{{field.intake}}</option>
            </select>
          </b-form-group>  
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="4">
            <b-form-group class="mb-0" label="Mode of Application" label-for="applicationMode">     
            <select v-model="applicationModeFilter" id='applicationMode' @change="filterapplicationMode($event)">
                <option selected>All</option>
                <option v-for='(field,ind) in applicationModeFilterArry' :key="ind">{{field.modeOfApplication}}</option>
            </select>
          </b-form-group> 
        </b-col>
        <b-col md="2" sm="4">
             <b-form-group class="mb-0" label="IELTS/TOEFL Band Scores" label-for="bandscore">     
            <select v-model="bandscoreFilter" id='bandscore' @change="filterBandScore($event)">
                <option selected>All</option>
                <option v-for='(field,ind) in bandscoreFilterArry' :key="ind">{{field.bandScore}}</option>
            </select>
          </b-form-group> 
        </b-col>
        <b-col md="2" sm="4">
            <b-form-group class="mb-0" label="German Language" label-for="germanLanguage">     
            <select v-model="germanLangFilter" id='germanLanguage' @change="filtergermanLang($event)">
                <option selected>All</option>
                <option v-for='(field,ind) in germanLangFilterArry' :key="ind">{{field.germanLanguage}}</option>
            </select>
          </b-form-group> 
        </b-col>       
      </b-row>
    </b-card> 
    <!-- Table  -->
    <b-card>
            <b-row class="my-1">     
                <!-- SEARCH INPUT -->
                <b-col
                md="2"
                sm="4"
                >
                <b-form-group    
                    class="mb-0"
                >     
                    <b-form-input
                        id="filterInput"
                        v-model="filter"
                        type="search"
                        size="sm"
                        placeholder="Type to Search"
                    />
                </b-form-group>
                </b-col>
                <b-col md="4"/><b-col md="2"/><b-col md="2"/>
                <!-- PAGINATION DROPDOWN -->
                <b-col
                md="2"       
                >
                <v-select
                  v-model="perPage"
                  style="width: 90px"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                </b-col>
                <!-- TABLE -->
                <b-col cols="12">
                <b-table
                    striped
                    hover
                    responsive
                    show-empty
                    empty-text="No matching records found"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="coursesList"
                    :fields="columns"       
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                <template #cell(no)="data">  
                    <b-col>{{Number(data.index + 1)}}</b-col>          
                </template> 
                <template #cell(intake)="data">  
                    <b-col>{{data.item.intake}}</b-col>          
                </template>                 
                <template #cell(action)="data">  
                    <b-col v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                        <b-img src='@/assets/images/erflog/Edit.png' @click="editCourse(data)" class="action_icon"/>
                        <b-img src='@/assets/images/erflog/Delete.png' @click="deleteCource(data)" class="action_icon"/>    
                    </b-col>
                    <div v-else>
                      --
                    </div>
                </template>  
                </b-table>
                <p v-show="coursesList.length == 0" style="color: red;font-size: small;font-style: italic;text-align: center;">
                    No Data Found
                </p>
                </b-col>
                <!-- PAGINATION FOOTER -->
                <b-col
                cols="12"
                >
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="universityList.length"
                        :per-page="perPage"
                        align="right"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                    >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
            </b-row>
    </b-card>   
    <!-- <div v-if='isLoading' class="loading"></div> -->
    <div v-if="isLoading" class="loading loading-custom">         
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>     
    </div>
    </div>
</template>
<script>
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'
import {
  BButton, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem, BPagination, BCol, BCard, BRow, BTable, BFormSelect, BImg
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
//FIREBASE IMPORT
import { dbCollections } from '@/utils/firebaseCollection.js';
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
const newLocal='#toggle-btn'
extend('required',required)
export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BPagination,
    BCol,
    BCard,
    BRow,
    BTable,
    BFormSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data(){
    return{
    model:{
        name: '',
        city:'',
        id:''
    },  
    editModel:{
        name: '',
        city:'',
        id:''
    },    
    nameState: null,
    nameCity:null,
    submittedNames: [],
    nameEditState: null,
    nameEditCity:null,
    filter:null,
    perPage: 10,
    pageOptions: [10, 25, 50, 100],
    currentPage: 1,
    universityList:[],
    columns:[
        {          
          label: 'No.',
          key: 'no'
        },
        {          
          label: 'Course Name',
          key: 'courseName'
        },
        {          
          label: 'Field Name',
          key: 'fieldName'
        },
        {          
          label: 'University Name',
          key: 'universityName'
        },
        {          
          label: 'IELTS/TOEFL Band Scores',
          key: 'bandScore'
        },
        {          
          label: 'Intake',
          key: 'intake'
        },
        {          
          label: 'Mode of Application',
          key: 'modeOfApplication'
        },
        {          
          label: 'German Language',
          key: 'germanLanguage'
        },
        {          
          label: 'Application Decline',
          key: 'applicationDecline'
        },
        {          
          label: 'Action',
          key: 'action'
        },
    ],
    filterOn: [],
    totalRows:1,
    isLoading:false,
    coursesList:[],
    courseFilterArry:[],
    //Filter Section    
    courseNameFilter:[],
    courseFilter:'',
    previuseSearchCource:'',
    universityFilter:'',
    previuseSearchuniversity:'',
    fieldFilter:'',
    intakeFilter:'',
    bandscoreFilter:'',
    germanLangFilter:'',
    applicationModeFilter:'',
    universityFilterArry:[],
    fieldFilterArry:[],    
    intakeFilterArry:[],    
    applicationModeFilterArry:[],    
    bandscoreFilterArry:[],    
    germanLangFilterArry:[]
    }    
  },
  watch: {
    $route: function () {        
        this.getData();          
    }
  },
  created(){
    var self = this;
    self.getData();
    self.$root.$on('createNewCourse',()=>{   
        self.$router.push({name:'createNewCourse'})      
    })
  },
  methods: {
    getData(){      
       getQuery.getDataWithSpecificWhereCondition(dbCollections.COURSES,'isDeleted','==',false,res=>{
            this.coursesList = [];  
            this.courseNameFilter = []; 
            this.universityFilterArry = []; 
            this.fieldFilterArry=[];  
            this.intakeFilterArry = [];
            this.applicationModeFilterArry = [];
            this.bandscoreFilterArry = []; 
            this.germanLangFilterArry = [];     
            if(res.length > 0){             
                res.forEach(element => {
                    let intake = element.intake.toString().replace(',','/');
                    let declineDate = moment(element.applicationDecline.toDate()).format('DD MMM, yyyy')                  
                    let obj ={
                        'no':element.id,                        
                        'courseName':element.courseName,
                        'fieldName':element.fieldData.name,
                        'universityName':element.universityData.name,
                        'bandScore':element.bandScores,
                        'intake':intake,
                        'modeOfApplication':element.modeOfApplication,
                        'germanLanguage':element.germanLanguage,
                        'applicationDecline':declineDate,                        
                        'action':false
                    };
                    this.coursesList.push(obj)                   
                    // FILTER ARRAYS OF COURCENAME                 
                    if(this.courseNameFilter.length > 0){
                      let findCource = this.courseNameFilter.findIndex(ele=>{                      
                        return ele.courseName.toLowerCase()==element.courseName.toLowerCase();
                      })
                      if(findCource !== -1){
                      this.courseNameFilter.splice(findCource,1)
                      }
                      this.courseNameFilter.push(obj)
                    }else{
                      this.courseNameFilter.push(obj)
                    }
                    // FILTER ARRAYS OF UNIVERSITYNAME                 
                    if(this.universityFilterArry.length > 0){
                       let findUni = this.universityFilterArry.findIndex(ele=>{                         
                         return ele.universityName.toLowerCase() == element.universityData.name.toLowerCase();
                       })                      
                      if(findUni !== -1){
                        this.universityFilterArry.splice(findUni,1)
                      }
                        this.universityFilterArry.push(obj)
                    }else{
                       this.universityFilterArry.push(obj)
                    }
                    // FILTER ARRAYS OF FIELD                 
                    if(this.fieldFilterArry.length > 0){
                       let findField = this.fieldFilterArry.findIndex(ele=>{                         
                         return ele.fieldName.toLowerCase() == element.fieldData.name.toLowerCase();
                       })                      
                      if(findField !== -1){
                        this.fieldFilterArry.splice(findField,1)                       
                      }
                        this.fieldFilterArry.push(obj)
                    }else{
                       this.fieldFilterArry.push(obj)
                    }                     
                    // FILTER ARRAYS OF INTAKE                 
                    if(this.intakeFilterArry.length > 0){
                       let findIntake = this.intakeFilterArry.findIndex(ele=>{
                         console.log(ele ,"==", element)
                         return ele.intake.toLowerCase() == intake.toLowerCase();
                       })                      
                      if(findIntake !== -1){
                        this.intakeFilterArry.splice(findIntake,1)
                        console.log(this.intakeFilterArry,"this.courseFilterArry")
                      }
                        this.intakeFilterArry.push(obj)
                    }else{
                       this.intakeFilterArry.push(obj)
                    }
                    // FILTER ARRAYS OF MODE OF APPLICATION                 
                    if(this.applicationModeFilterArry.length > 0){
                       let findmodeApp = this.applicationModeFilterArry.findIndex(ele=>{
                         console.log(ele ,"==", element)
                         return ele.modeOfApplication.toLowerCase() == element.modeOfApplication.toLowerCase();
                       })                      
                      if(findmodeApp !== -1){
                        this.applicationModeFilterArry.splice(findmodeApp,1)
                        console.log(this.applicationModeFilterArry,"this.courseFilterArry")
                      }
                        this.applicationModeFilterArry.push(obj)
                    }else{
                       this.applicationModeFilterArry.push(obj)
                    }
                    // FILTER ARRAYS OF MODE OF Band SCORE                 
                    if(this.bandscoreFilterArry.length > 0){
                       let findscore = this.bandscoreFilterArry.findIndex(ele=>{
                         console.log(ele ,"==", element)
                         return ele.bandScore == element.bandScores;
                       })                      
                      if(findscore !== -1){
                        this.bandscoreFilterArry.splice(findscore,1)
                        console.log(this.bandscoreFilterArry,"this.courseFilterArry")
                      }
                        this.bandscoreFilterArry.push(obj)
                    }else{
                       this.bandscoreFilterArry.push(obj)
                    }
                    // FILTER ARRAYS OF MODE OF GERMAN-LANGUAGE FILTER                
                    if(this.germanLangFilterArry.length > 0){
                       let findscore = this.germanLangFilterArry.findIndex(ele=>{
                         console.log(ele ,"==", element)
                         return ele.germanLanguage == element.germanLanguage;
                       })                      
                      if(findscore !== -1){
                        this.germanLangFilterArry.splice(findscore,1)
                        console.log(this.germanLangFilterArry,"this.courseFilterArry")
                      }
                        this.germanLangFilterArry.push(obj)
                    }else{
                       this.germanLangFilterArry.push(obj)
                    }
                });
            }else{
                this.coursesList = [];
            }
             this.courseFilterArry = this.coursesList;           
       }) 
    },     
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterCourceName(evt){      
      // this.previuseSearchCource = '';
      // if(this.previuseSearchCource == ''){
      //   this.previuseSearchCource = this.courseFilter;
      // }
      this.universityFilter='';
      this.fieldFilter='';
      this.intakeFilter='';
      this.bandscoreFilter='';
      this.germanLangFilter='';
      this.applicationModeFilter='';  
            var val = evt.target.value;
            console.log(evt,val)
            if (val.toLowerCase() == 'all') {
                this.coursesList = this.courseFilterArry;
            } else{ 
              // if(this.previuseSearchCource == this.courseFilter){
              //   console.log("*************")
              //   this.coursesList = this.coursesList.filter(function (e){ return e.courseName.toLowerCase() == val.toLowerCase();})  
              //   // this.previuseSearchCource = ''        
              // }else{
                // alert("courseFilterArry")
                this.coursesList = this.courseFilterArry.filter(function (e){ return e.courseName.toLowerCase() == val.toLowerCase();})                
              // }
            }
    },
    filterUniversityName(evt){ 
      // this.previuseSearchuniversity = '';
      // if(this.previuseSearchuniversity == ''){
      //   this.previuseSearchuniversity = this.universityFilter;
      // }  
      this.courseFilter='';     
      this.fieldFilter='';
      this.intakeFilter='';
      this.bandscoreFilter='';
      this.germanLangFilter='';
      this.applicationModeFilter=''; 
        var val = evt.target.value;
        console.log(evt,val)
        if (val.toLowerCase() == 'all') {
            this.coursesList = this.courseFilterArry;
        } else{             
            // if(this.previuseSearchuniversity == this.universityFilter){
            //   this.coursesList = this.coursesList.filter(function (e){ return e.universityName.toLowerCase() == val.toLowerCase();})
            // }else{
               this.coursesList = this.courseFilterArry.filter(function (e){ return e.universityName.toLowerCase() == val.toLowerCase();})              
            // }
        }
    },
    filterFieldName(evt){    
      this.courseFilter='';
      this.universityFilter='';     
      this.intakeFilter='';
      this.bandscoreFilter='';
      this.germanLangFilter='';
      this.applicationModeFilter=''; 
        var val = evt.target.value;
        console.log(evt,val)
        if (val.toLowerCase() == 'all') {
            this.coursesList = this.courseFilterArry;
        } else{ 
            this.coursesList = this.courseFilterArry.filter(function (e){ return e.fieldName.toLowerCase() == val.toLowerCase();})
        }
    },
    filterIntakeName(evt){ 
      this.courseFilter='';
      this.universityFilter='';
      this.fieldFilter='';     
      this.bandscoreFilter='';
      this.germanLangFilter='';
      this.applicationModeFilter='';    
        var val = evt.target.value;
        console.log(evt,val)
        if (val.toLowerCase() == 'all') {
            this.coursesList = this.courseFilterArry;
        } else{ 
            this.coursesList = this.courseFilterArry.filter(function (e){ return e.intake.toLowerCase() == val.toLowerCase();})
        }
    },
    filterapplicationMode(evt){ 
      this.courseFilter='';
      this.universityFilter='';
      this.fieldFilter='';
      this.intakeFilter='';
      this.bandscoreFilter='';
      this.germanLangFilter=''; 
        var val = evt.target.value;
        console.log(evt,val)
        if (val.toLowerCase() == 'all') {
            this.coursesList = this.courseFilterArry;
        } else{ 
            this.coursesList = this.courseFilterArry.filter(function (e){ return e.modeOfApplication.toLowerCase() == val.toLowerCase();})
        }
    },
    filterBandScore(evt){   
      this.courseFilter='';
      this.universityFilter='';
      this.fieldFilter='';
      this.intakeFilter='';     
      this.germanLangFilter='';
      this.applicationModeFilter='';  
        var val = evt.target.value;
        console.log(evt,val)
        if (val.toLowerCase() == 'all') {
            this.coursesList = this.courseFilterArry;
        } else{ 
            this.coursesList = this.courseFilterArry.filter(function (e){ return e.bandScore.toLowerCase() == val.toLowerCase();})
        }
    },
    filtergermanLang(evt){   
      this.courseFilter='';
      this.universityFilter='';
      this.fieldFilter='';
      this.intakeFilter='';
      this.bandscoreFilter='';      
      this.applicationModeFilter='';  
        var val = evt.target.value;
        console.log(evt,val)
        if (val.toLowerCase() == 'all') {
            this.coursesList = this.courseFilterArry;
        } else{ 
            this.coursesList = this.courseFilterArry.filter(function (e){ return e.germanLanguage.toLowerCase() == val.toLowerCase();})
        }
    },
    editCourse(data){
     if(this.isLoading!==true){     
      this.$router.push({
        'name':'editCourse',
        params:{'courceId': data.item.no}
      })
     }
    },
    deleteCource(data){
      console.log(data)     
      if(this.isLoading!==true){       
        this.$swal({
          title: 'Delete',
          text: "Are you sure you want to delete?",
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText:'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {            
              this.isLoading = true;
               let id= data.item.no
              let updateObj = {
                'isDeleted': true
              }      
              if(id !== undefined){                
              updateQuery.updateRootCollectionDataByDocId(dbCollections.COURSES,id,updateObj,res=>{
              console.log(res)                
              this.isLoading= false;              
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              })
            }           
          }
        })
        }    
    }
  },
}
</script>
<style>

@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>